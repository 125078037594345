import React from "react";
const Logo = (props) => {
  return (
    <div className="logoWrapper">
      <h1>
        20 <span>squared</span>
      </h1>
    </div>
  );
};

export default Logo;
